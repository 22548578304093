<template>
  <div class="p-t-3">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <date-picker
          v-model="selectedYear"
          type="year"
          format="YYYY"
          lang="id"
          placeholder="Pilih Tahun"
        />
      </div>
    </div>
    <div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
    <canvas v-show="!loading" ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import axios from 'axios'
import DatePicker from 'vue2-datepicker'
import moment from 'moment'

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      selectedYear: new Date(), // Default ke tahun sekarang
      reportData: [],
      chartInstance: null,
      loading: false, // Menambahkan state loading
    }
  },
  watch: {
    selectedYear: {
      handler() {
        this.fetchReportData()
      },
      immediate: true,
    },
  },
  methods: {
    async fetchReportData() {
      this.loading = true // Aktifkan loading
      this.reportData = Array(12).fill({
        total_revenue: 0,
        total_profit: 0,
        total_refund: 0,
      })

      const year = moment(this.selectedYear).format('YYYY')
      const idCompany = JSON.parse(localStorage.getItem('id_company'))
      const listBranch = JSON.parse(localStorage.getItem('list_branch'))
      const idBranch =
        listBranch && listBranch.length > 0 ? listBranch[0].id : null

      try {
        const requests = []

        for (let month = 0; month < 12; month++) {
          const startDate = moment(`${year}-${month + 1}-01`)
            .startOf('month')
            .format('YYYY-MM-DD')
          const endDate = moment(`${year}-${month + 1}-01`)
            .endOf('month')
            .format('YYYY-MM-DD')

          await new Promise((resolve) => setTimeout(resolve, 50))

          const response = await axios.get(
            `http://localhost:8000/total-report-jobfile/0/${startDate}/${endDate}/${idBranch}/${idCompany}`
          )

          requests.push({
            total_revenue: this.cleanCurrency(response.data.total_revenue),
            total_profit: this.cleanCurrency(response.data.total_profit),
            total_refund: this.cleanCurrency(response.data.total_refund),
          })
        }

        this.reportData = requests
        this.initYearlyChart()
      } catch (error) {
        console.error('Error fetching report data:', error)
      } finally {
        this.loading = false // Matikan loading setelah data diambil
      }
    },
    cleanCurrency(value) {
      if (typeof value === 'string') {
        return parseFloat(value.replace(/[^\d.-]/g, '')) || 0
      }
      return value || 0
    },
    initYearlyChart() {
      if (!this.$refs.chartCanvas || !this.reportData) return

      if (this.chartInstance) {
        this.chartInstance.destroy()
      }

      setTimeout(() => {
        const ctx = this.$refs.chartCanvas.getContext('2d')
        const permissions = JSON.parse(
          localStorage.getItem('userPermissions') || '[]'
        )

        this.chartInstance = new Chart(ctx, {
          type: 'line',
          data: {
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            datasets: [
              ...(permissions.includes('WIDGET_REVENUE_ENABLE')
                ? [
                    {
                      label: 'Total Revenue',
                      data: this.reportData.map((data) => data.total_revenue),
                      backgroundColor: 'rgba(79, 198, 225, 0.2)',
                      borderColor: '#4fc6e1',
                      borderWidth: 2,
                      pointBackgroundColor: '#4fc6e1',
                      fill: true,
                    },
                  ]
                : []),
              ...(permissions.includes('WIDGET_PROFIT_ENABLE')
                ? [
                    {
                      label: 'Total Profit',
                      data: this.reportData.map((data) => data.total_profit),
                      backgroundColor: 'rgba(81, 193, 173, 0.2)',
                      borderColor: '#51C1AD',
                      borderWidth: 2,
                      pointBackgroundColor: '#51C1AD',
                      fill: true,
                    },
                  ]
                : []),
              ...(permissions.includes('WIDGET_REFUND_ENABLE')
                ? [
                    {
                      label: 'Total Refund',
                      data: this.reportData.map((data) => data.total_refund),
                      backgroundColor: 'rgba(234, 76, 64, 0.2)',
                      borderColor: '#EA4C40',
                      borderWidth: 2,
                      pointBackgroundColor: '#EA4C40',
                      fill: true,
                    },
                  ]
                : []),
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label(tooltipItem, data) {
                  let value =
                    data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                    ]
                  return (
                    'IDR ' +
                    value.toLocaleString('id-ID', { minimumFractionDigits: 2 })
                  )
                },
              },
            },
          },
        })
      }, 500)
    },
  },
}
</script>

<style scoped>
canvas {
  width: 100% !important;
  max-height: 500px !important;
}
.loading-overlay {
  position: absolute;
  top: 200%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #4fc6e1;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
