<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs pt-3" style="border: 0">
          <li class="active">
            <a href="#" class="btn btn-sm btn-head text-white"
              >Widget Revenue</a
            >
          </li>
        </ul>
        <div class="card new-card">
          <div class="card-body">
            <RevenueChart />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import RevenueChart from '@/src/components/Widget/revenueChart.vue'

export default {
  Page: {
    title: 'Widget Revenue',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    RevenueChart,
  },
}
</script>
